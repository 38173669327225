import React from "react";
import { Box } from "@mui/material";
import DomainSpecificProgrammingExperiment from "../../components/experiments/domain-specific-programming-experiment/domain-specific-programming-experiment";
import Typography from "@mui/material";

export default () => {
  return (
    <Box mx={2}>
      <DomainSpecificProgrammingExperiment />
    </Box>
  );
};
