import React, { useContext, useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import { AuthContext } from "../../../providers/auth-provider";

import AceEditor from '../../../components/code-editor/ace';

import {
  getExperimentData,
  postExperimentData,
} from "../../../http-actions/experiment-actions";

import programmingExerciseArea from "./programming-exercise/programming-exercise-area";

// TODO: need to probably add this to the database as well
const experimentUuid = "edddab38-5cde-477b-b15f-50d1e205a0bb";

const DomainSpecificProgrammingExperiment = (props) => {
  const [experimentData, setExperimentData] = useState(null);
  const [experimentAnswered, setExperimentAnswered] = useState(false);
  const { state: authState } = useContext(AuthContext);

  const sendExperimentData = async (data) => {
    console.log("Posting data to the backend");
    console.log(data);
    await postExperimentData(authState.token, experimentUuid, data);
  };

  const loadExperimentData = async () => {
    if (!authState.token) {
      console.log("No token in authstate...");
      return;
    }

    console.log("Loading experiment");
    const data = await getExperimentData(authState.token, experimentUuid);
    console.log(data);

    if (data.partsCompleted > 0) {
      setExperimentAnswered(true);
    }

    setExperimentData(data);
  };

  useEffect(() => {
    console.log("Authstate changed..");
    if (!authState.token) {
      console.log("No token in authstate...");
      return;
    }

    loadExperimentData();
  }, [authState]);

  if (experimentAnswered) {
    return <></>;
  }

  // if (!experimentData ||
  //     !experimentData.experimentType) {
  //     return <>
  //         <Typography>Loading content: Experiment type not available</Typography>
  //     </>;
  // }

  // if (!experimentData ||
  //     !experimentData.data) {
  //     return <>
  //         <Typography>Loading content: Experiment data not available</Typography>
  //     </>;
  // }

  return (
    <Container maxWidth="md" sx={{ pt: 5 }}>
      <Typography gutterBottom>Nullam eu arcu diam. Vivamus in mi turpis. Aliquam fermentum venenatis tempus. In fermentum congue ex ut euismod. Maecenas egestas quam eros. Praesent faucibus elit at porta viverra. Curabitur vulputate felis sed tempor gravida. Fusce velit leo, dictum non eleifend eget, varius tempus risus. Praesent nec ex et ante dictum consectetur ac non enim. Donec id mi rutrum, bibendum velit eu, pulvinar sapien. Donec luctus, dui et bibendum tristique, lacus metus posuere lorem, ac sodales sem ipsum id turpis. Integer elementum neque lacus, id finibus dolor cursus a. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ornare volutpat elementum.</Typography>
      <Typography gutterBottom>Like other programming languages, Python has conditional statements that are used to control the flow of the program. In Python, the conditional statements include `if`, `elif`, and `else`.The following outlines how to use conditional statements in Python.</Typography>
      <AceEditor
        noCache
        mode="python-pytamaro"
        code={`
        show_graphic(square(100, red))
      `} />
      <Typography gutterBottom>More lorem ipsum...</Typography>
      <AceEditor
        noCache
        mode="python-turtle"
        code={`
        square(100)
      `} />
    </Container>
  );
};

export default DomainSpecificProgrammingExperiment;
